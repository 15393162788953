<script setup>
import { ref } from 'vue'
import svgLogo from '@/Elements/SvgLogo.vue'

const hover = ref(false)

function mouseenter(){
    hover.value = true
}
function mouseleave(){
    hover.value = false

}

</script>
<template>
    <a href="/dashboard" class="logo-text" :class="hover ? 'animation-to-centered' : 'animation-to-normal'"
        @mouseenter="mouseenter" @mouseleave="mouseleave">
        <svgLogo/>
    </a>
</template>